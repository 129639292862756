.LoginForm {
    background: #ffffff;
    border-radius: 5px;
    padding: 32px;
    width: 368px;
    max-width: 100%;

    &__submit {
        height: 48px;
        width: 100%;
        font-weight: bold;
    }

    &__title {
        font-weight: bold;
        font-size: 24px;
        line-height: 1.25;
        margin-bottom: 26px;
        text-align: center;
    }

    &__inputContainer {
        margin-bottom: 16px;
    }

    &__input {
        height: 48px;
    }
}
