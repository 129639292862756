@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

@primary-color: #f47f16;

.RootView {
    height: 100%;
    min-height: 100vh;
}

.Layout {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__main {
        flex-grow: 1;
    }
}

.leaflet-top,
.leaflet-bottom {
    z-index: 999;
}
